import React from "react"

const Preamble = ({ text }) => {
    return (
        <div style={{fontSize: '0.8em', margin: '0.8em'}}>
            {
                text.split('\n').map(p=>{
                    return <><span>{p}</span><br/></>
                })
            }
        </div>
    )
}

export default Preamble