import React from "react"

import NormalTag from "../tags/NormalTag"
const _ = require("lodash")

const NormalTags = (props) => {
    const posts = props.posts

    // タグ抽出
    let tags = []
    // Iterate through each post, putting all found tags into `tags`
    _.each(posts, edge => {
        if (_.get(edge, "node.frontmatter.tags")) {
            tags = tags.concat(edge.node.frontmatter.tags)
        }
    })

    // 重複タグ除外
    tags = _.uniq(tags)

    const getNormalTags = (tags) => {
        const normalTags = []
        tags.forEach((tag, i) => {
            normalTags.push(<NormalTag key={i} tag={tag} />)
        })
        return normalTags
    }

    return (
        <>
            <h4>最近のタグ</h4>
            <div className="d-block">
                {getNormalTags(tags)}
            </div>
        </>
    )
}


export default NormalTags