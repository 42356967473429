import React from "react"

import "./tags.css"
import { Link } from "gatsby";
const _ = require("lodash")

const NormalTag = (props) => {
    const { tag } = props
    const tagSlug = _.kebabCase(tag.replace('#','Sharp'))

    return (
        <div className="d-inline-block p-1">
            <Link to={`/tags/${tagSlug}/`}>
                <button
                    className="normal-tag">
                    <p className="d-inline">{tag} </p>
                </button>
            </Link>
        </div>

    )
}

export default NormalTag