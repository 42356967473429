import React from "react"
import {
    AiOutlineTwitter,
    AiOutlineMail
} from "react-icons/ai"
import "./sidebar.css"


const SocialLinks = ({ contacts }) => {
    return (
        <div className="side-social-links float-left mt-3 mb-3">
            <a className="text-secondary p-2"
                href={contacts.twitter}>
                <span title="Twitter">
                    <AiOutlineTwitter size={26} style={{ color: "secondary" }} />
                </span>
            </a>
            <a className="text-secondary p-2"
                href={contacts.email}>
                <span title="Email">
                    <AiOutlineMail size={26} style={{ color: "secondary" }} />
                </span>
            </a>
        </div>
    )
}

export default SocialLinks